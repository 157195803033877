import React from "react"
import Obfuscate from "react-obfuscate";

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
      <SEO title="Home" />

      <div className="mainTitle">Ólafur Gauti<br/>Guðmundsson</div>

<p>Hi, I'm an entrepreneur who loves to build products - living in Seltjarnarnes, Iceland. </p>
<p>I was a Senior Director of Engineering at <a href="https://www.tripadvisor.com">Tripadvisor</a>.
    Previously Co-founder and CTO of <a href="https://bokun.io">Bókun</a> (acquired by Tripadvisor in 2018).
</p>
<p>You can reach out to me at <Obfuscate email="olafur@gudmundsson.online" /></p>

    </Layout>
)

export default IndexPage
